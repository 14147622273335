<div class="container-fluid gx-0 ngm">
  <!-- TITLE AND SEARCHBAR -->
  <section class="row welcome mb-5 px-0 ngm">
    <div class="col-12 my-2 ngm px-5">
      <h1
        class="title text-start"
        [innerHTML]="'LANDING_PAGE.COMPANY.TITLE' | translate | uppercase"
      ></h1>
      <p class="sub-title text-start mb-sm-0 ngm">
        {{ "LANDING_PAGE.COMPANY.SUB_TITLE_1" | translate }}
        <span class="d-inline d-sm-none">
          {{ "LANDING_PAGE.COMPANY.SUB_TITLE_2" | translate }}
        </span>
      </p>
      <p class="sub-title text-start d-sm-block d-none mt-0 ngm">
        {{ "LANDING_PAGE.COMPANY.SUB_TITLE_2" | translate }}
      </p>
      <button class="btn btn-green mt-4 ngm front" (click)="toRegister()">
        {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
      </button>
    </div>
    <!-- SEARCH COMPONENT -->
    <div class="search px-0 ngm">
      <!-- HERO IMG -->
      <div class="hero-img-container ">
        <img
          [src]="'/assets/images/landing-pages/hero_0' + heroImg + '.png'"
          alt=""
        />
      </div>
      <app-search-campaign [userType]="userType" *ngIf="showSearchCampaign"></app-search-campaign>
    </div>
  </section>

  <!-- ACTION 1 - CREA ANNUNCIO -->
  <section class="action-1">
    <!-- BIGGER SCREENS (LG+)-->
    <div class="d-none d-lg-flex ngm card card-action card-action-1">
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE.COMPANY.ACTION_1.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE.COMPANY.ACTION_1.TEXT_1" | translate }}
        </p>
        <p
          class="card-subtitle mt-1 ngm text-start"
          [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_1.TEXT_2' | translate"
        ></p>
        <button class="btn btn-green mt-4 ngm" (click)="toRegister()">
          {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
        </button>
      </div>
      <div class="img-container"></div>
    </div>
    <!-- SMALLER SCREENS -->
    <div
      class="d-flex d-lg-none flex-column card card-action card-action-1 mx-auto ngm"
    >
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE.COMPANY.ACTION_1.TITLE' | translate | uppercase
          "
        ></h4>
        <p class="card-subtitle mt-1 ngm text-start">
          {{ "LANDING_PAGE.COMPANY.ACTION_1.TEXT_1" | translate }}
        </p>
        <p
          class="card-subtitle mt-1 ngm text-start"
          [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_1.TEXT_2' | translate"
        ></p>
        <div class="d-grid">
          <button
            class="btn-mobile btn-green mt-4 ngm"
            (click)="toRegister()"
          >
            {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- COUNTERS - MD SCREENS -->
  <section
    class="d-flex d-lg-none counter-container justify-content-center ngm"
  >
    <div>
      <p class="counter">
        {{ "LANDING_PAGE.COMPANY.COUNTER.TEXT_1" | translate | uppercase }}
      </p>
      <p class="counter-label">
        {{ "LANDING_PAGE.COMPANY.COUNTER.LABEL_1" | translate }}
      </p>
    </div>
    <div>
      <p class="counter">
        {{ "LANDING_PAGE.COMPANY.COUNTER.TEXT_2" | translate | uppercase }}
      </p>
      <p class="counter-label">
        {{ "LANDING_PAGE.COMPANY.COUNTER.LABEL_2" | translate }}
      </p>
    </div>
    <div>
      <p class="counter">
        {{ "LANDING_PAGE.COMPANY.COUNTER.TEXT_3" | translate | uppercase }}
      </p>
      <p class="counter-label">
        {{ "LANDING_PAGE.COMPANY.COUNTER.LABEL_3" | translate }}
      </p>
    </div>
  </section>

  <!-- WHY US -->
  <section class="why-us">
    <!-- BIGGER SCREENS (LG+)-->
    <div
      class="d-none d-lg-flex row action-container why-us justify-content-center align-items-center gap-5 ngm"
    >
      <div class="col-12 col-lg-6 img-container"></div>
      <div class="col-12 col-lg-6 text mb-4 mb-md-4 ngm text-container">
        <h4
          class="title text-start"
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.TITLE' | translate"
        ></h4>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TITLE' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_1" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_1' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_2" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_2' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_3" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_3' | translate"
        ></p>

        <button class="btn btn-green mt-4" (click)="toRegister()">
          {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
        </button>
      </div>
    </div>
    <!-- SMALLER SCREENS (LG+)-->
    <div class="d-flex flex-column d-lg-none card card-action why-us ngm">
      <div class="img-container"></div>
      <div class="text-container">
        <h4
          class="card-title"
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.TITLE' | translate"
        ></h4>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TITLE' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_1" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_1' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_2" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_2' | translate"
        ></p>

        <h5>{{ "LANDING_PAGE.COMPANY.WHY_US.TEXT_3" | translate }}</h5>
        <p
          [innerHTML]="'LANDING_PAGE.COMPANY.WHY_US.SUB_TEXT_3' | translate"
        ></p>

        <div class="d-grid">
          <button class="btn-mobile btn-green mt-4 ngm" (click)="toRegister()">
            {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- COUNTERS AND ACTION 2 CARD - LG+ SCREEN -->
  <section class="d-none d-lg-block counter-container px-0 ngm">
    <div class="d-flex flex-row justify-content-center ngm">
      <app-counter
        [params]="{ start: 0, end: 3000, interval: 50, increment: 30 }"
        [label]="'LANDING_PAGE.COMPANY.COUNTER.LABEL_1' | translate"
        [thousandSeparator]="true"
      ></app-counter>
      <app-counter
        [params]="{ start: 0, end: 159000, interval: 50, increment: 1590 }"
        [label]="'LANDING_PAGE.COMPANY.COUNTER.LABEL_2' | translate"
        [thousandSeparator]="true"
      ></app-counter>
      <app-counter
        [params]="{ start: 0, end: 90, interval: 50, increment: 1 }"
        [label]="'LANDING_PAGE.COMPANY.COUNTER.LABEL_3' | translate"
        [thousandSeparator]="true"
      ></app-counter>
    </div>
    <!-- ACTION 2 - CALCOLA PREVENTIVO LG+ SCREEN -->
    <div class="card card-action card-action-2">
      <div class="text-container">
        <h4
          class="card-title text-start"
          [innerHTML]="
            'LANDING_PAGE.COMPANY.ACTION_2.TITLE' | translate | uppercase
          "
        ></h4>
        <p
          class="card-subtitle mt-1 ngm text-start"
          [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_2.TEXT' | translate"
        ></p>
        <button class="btn btn-green mt-4 ngm" (click)="toRegister()">
          {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
        </button>
      </div>
      <div class="img-container"></div>
    </div>
  </section>

  <!-- ACTION 2 CARD - MD SCREENS -->
  <section
    class="d-flex flex-column d-lg-none card card-action card-action-2 ngm"
  >
    <div class="img-container"></div>
    <div class="text-container">
      <h4
        class="card-title text-start"
        [innerHTML]="
          'LANDING_PAGE.COMPANY.ACTION_2.TITLE' | translate | uppercase
        "
      ></h4>
      <p
        class="card-subtitle mt-1 ngm text-start"
        [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_2.TEXT' | translate"
      ></p>
      <div class="d-grid">
        <button
          class="btn-mobile btn-green mt-4 ngm"
          (click)="toRegister()"
        >
          {{ "LANDING_PAGE.COMPANY.ACTION_4.BUTTON" | translate }}
        </button>
      </div>
    </div>
  </section>

  <!-- COME FUNZIONA -->
  <section class="how-it-works">
    <app-how-it-works-landing></app-how-it-works-landing>
  </section>

  <!-- PARTNERSHIPS -->
  <section class="partnerships-container ngm">
    <h4
      class="title"
      [innerHTML]="'LANDING_PAGE.COMPANY.PARTNERSHIPS' | translate"
    ></h4>
    <div class="logo-container">
      <div
        *ngFor="let step of [].constructor(3); index as i"
        class="partner-logo"
        [id]="'partner-logo' + (i + 1)"
        [style.background]="
          'url(/assets/images/landing-pages/partner_logo_' +
          (i + 1) +
          '.png) no-repeat center center'
        "
      ></div>
    </div>
  </section>

  <!-- ACTION 3 - SCARICA L'APP -->
  <!-- LG+ SCREENS -->
  <!-- <section class="download-app action-container action-3 d-none d-lg-flex">
    <div class="img-container"></div>
    <div class="mb-lg-4 ngm text-container">
      <h4
        class="title text-start mt-2 ngm"
        [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_3.TITLE' | translate"
      ></h4>

      <div class="store-icons-container mt-4 ngm">
        <a [href]="store.apple" target="_blank">
          <img
            src="../../../../../assets/images/app_store_badge.svg"
            alt="download InPlace app from App Store"
            class="me-4 ngm"
          />
        </a>
        <a [href]="store.google" target="_blank">
          <img
            src="../../../../../assets/images/play_store_badge.svg"
            alt="download InPlace app from Play Store"
          />
        </a>
      </div>
    </div>
  </section> -->
  <!-- MD SCREENS -->
  <!-- <section
    class="download-app action-container card card-action card-action-3 justify-content-end d-flex flex-column d-lg-none"
  >
    <div class="img-container"></div>
    <div class="text-container">
      <h4
        class="card-title text-start mb-4 ngm"
        [innerHTML]="'LANDING_PAGE.COMPANY.ACTION_3.TITLE' | translate"
      ></h4>

      <div class="store-icons-container ngm">
        <a [href]="store.apple" target="_blank">
          <img
            src="/assets/images/app_store_badge.svg"
            alt="download InPlace app from App Store"
            class="me-4 ngm"
        /></a>
        <a [href]="store.google" target="_blank">
          <img
            src="/assets/images/play_store_badge.svg"
            alt="download InPlace app from Play Store"
        /></a>
      </div>
    </div>
  </section> -->
</div>