import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserType } from '../../../../../core/interfaces/user-type.enum';
import { OverlayService } from '../../../overlay/overlay.service';
import { PixelCompanyLandingService } from './pixel-company-landing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-landing',
  templateUrl: './company-landing.component.html',
  styleUrls: ['./company-landing.component.scss'],
})
export class CompanyLandingComponent implements OnInit {
  UserType = UserType;
  userType: UserType = UserType.COMPANY;
  store = environment.store;
  heroImg = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
  showSearchCampaign = false

  constructor(
    private overlayService: OverlayService,
    private pixelCompanyService: PixelCompanyLandingService,
    private router: Router
  ) {}

  openLoginOverlay() {
    this.overlayService.generalLoginOverlayOpen();
  }

  ngOnInit() {
    this.pixelCompanyService.load();    
    setTimeout(() => this.showSearchCampaign = true, 100)
  }

  toRegister() {
    this.router.navigate(['auth/register/general'], {
      queryParams: { userType: UserType.COMPANY },
    });
  }
}
